import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Link, Stack, Text } from '@chakra-ui/layout';
import { Input } from '@chakra-ui/input';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { FormLabel } from '@chakra-ui/form-control';
import { Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/slider';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { Image } from '@chakra-ui/image';
import { Spinner } from '@chakra-ui/react';
import { toDateString, toTimeString } from './utils/dateHelpers';
import { enterShow } from './utils/networkHelpers';
import { getUserId, logOut } from './utils/cookieHelpers';
import LotteryContainer from './LotteryContainer';

export default class Lottery extends Component {
  static propTypes = {
    show: PropTypes.object,
    userData: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  state = {
    isSubmitting: false,
    price: this.props.show.cost,
    tickets: null,
    firstName: "",
    lastName: "",
    email: "",
    showDateIds: [this.props.show.id],
    alreadyEnteredShowDateIds: [],
  };

  componentDidMount() {
    if (this.props.userData) {
      let alreadyEnteredShowDateIds = [];
      if (this.props.userData.enteredDates !== "") {
        alreadyEnteredShowDateIds = this.props.userData.enteredDates.split(",");
      }
      const showDateIds = alreadyEnteredShowDateIds.slice();
      if (showDateIds.indexOf(this.props.show.id) === -1) {
        showDateIds.push(this.props.show.id);
      }
      this.setState({
        price: this.props.userData.lastMaxPrice,
        tickets: this.props.userData.lastNumPeople,
        alreadyEnteredShowDateIds,
        showDateIds,
      });
    }
  }

  handleEnterShow = () => {
    if (this.state.tickets == null) {
      return; // Complete logic
    }
    const showIds = this.state.showDateIds.join(",");
    const userId = getUserId();
    this.setState({ isSubmitting: true });
    enterShow(
      showIds,
      this.state.tickets,
      this.state.price,
      userId,
      this.state.firstName,
      this.state.lastName,
      this.state.email
    ).then(res => {
      this.setState({ isSubmitting: false });
      if (res.success === true) {
        this.props.onSubmit();
      }
    });
  }

  handlePriceChange = price => {
    this.setState({ price });
  }

  handleNumTickets = tickets => {
    this.setState({ tickets });
  }

  handleFirstNameChange = event => {
    const firstName = event.target.value;
    this.setState({ firstName });
  }

  handleLastNameChange = event => {
    const lastName = event.target.value;
    this.setState({ lastName });
  }

  handleEmailChange = event => {
    const email = event.target.value;
    this.setState({ email });
  }

  handleDatesChange = showDateIds => {
    this.setState({ showDateIds });
  }

  handleLogOut = () => {
    logOut();
  }

  renderPriceSlider() {
    // TODO: Improve
    var min = 20;
    var max = 80;
    if (this.props.show.name.includes("Hyprov")) {
      min = 29;
      max = 79;
    } else if (this.props.show.name.includes("Stranger Sings")) {
      min = 20;
      max = 51;
    }
    const defaultValue = this.state.price;
    const sliderMarks = [];
    for (var i = min; i <= max; i += 10) {
      sliderMarks.push(
        <SliderMark value={i} mt='1' ml='-2.5' fontSize='sm'>
          ${i}
        </SliderMark>
      );
    }
    return (
      <Box px={10}>
        <Slider min={min} max={max} value={defaultValue} onChange={this.handlePriceChange} disabled={this.state.isSubmitting}>
          {sliderMarks}
          <SliderMark
            value={this.state.price}
            textAlign='center'
            bg='blue.500'
            color='white'
            mt='1'
            ml='-6'
            w='12'
          >
            ${this.state.price}
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    );
  }

  renderOtherDates() {
    const otherDates = this.props.show.otherDates.map(date => {
      const datetime = new Date(date.time);
      const disabled = this.state.alreadyEnteredShowDateIds.indexOf(date.id) > -1 || this.state.isSubmitting;
      return (<Checkbox key={date.id} name={date.id} value={date.id} isDisabled={disabled} colorScheme='blue'>
        {toDateString(datetime)} - {toTimeString(datetime)}
      </Checkbox>);
    });
    return (
      <CheckboxGroup pl={4} spacing={2} onChange={this.handleDatesChange} value={this.state.showDateIds}>
        {otherDates}
      </CheckboxGroup>
    )
  }

  getNumTicketsVariant = num => {
    if (this.state.tickets === num) {
      return 'solid';
    }
    return 'outline';
  }

  maybeRenderEmail = () => {
    if (this.props.userData) {
      return <Text>{this.props.userData.email}</Text>
    } else {
      return <Input id='email' placeholder='Email address' onChange={this.handleEmailChange} disabled={this.state.isSubmitting} />;
    }
  }

  maybeRenderName = () => {
    if (this.props.userData) {
      return <Text>{this.props.userData.firstName} {this.props.userData.lastName} <Link color='grey' onClick={this.handleLogOut}>(Not you?)</Link></Text>
    } else {
      return [
          <Input id='fName' placeholder='First Name' mr={5} onChange={this.handleFirstNameChange} disabled={this.state.isSubmitting} />,
          <Input id='lName' placeholder='Last Name' onChange={this.handleLastNameChange} disabled={this.state.isSubmitting} />
      ];
    }
  }

  maybeRenderSubmitButton = () => {
    if (this.state.isSubmitting) {
      return <Button colorScheme='blue'><Spinner /></Button>
    }
    return <Button colorScheme='blue' onClick={this.handleEnterShow}>Enter!</Button>
  }

  renderLocation = () => {
    var locationUrl = '';
    // TODO: Improve this
    if (this.props.show.location.includes("Daryl")) {
      locationUrl = 'https://goo.gl/maps/xr7TCq4qhYCJzTzL8';
    } else if (this.props.show.location.includes("Playhouse")) {
      locationUrl = 'https://goo.gl/maps/qCSb8TH3A4aZi2UT9';
    }
    return <Link href={locationUrl} target='_blank'>{this.props.show.location}</Link>
  }

  renderExtraTerms = () => {
    // TODO: Improve
    if (this.props.show.name.includes("Hyprov")) {
      return [
        <Text>The art of Hypnosis is used in this production. Participating in the on-stage portion of the performance is completely voluntary. By volunteering to participate on stage in HYPROV, you also agree to the <Link textDecoration="underline" href='https://drive.google.com/file/d/12DVyw74pm5U_3Bn0nJUdfPBkNpkuYRMv/view' target='_blank'>HYPROV Volunteer Release (found here).</Link></Text>,
        <Text>At this time, all guests are strongly encouraged, but not required, to wear a mask in the theatre to protect themselves and others.</Text>,
        <Text>Vaccinations are not currently required. These protocols are subject to change.
        Full Details on the <Link textDecoration="underline" href='https://www.telecharge.com/Off-Broadway/HYPROV-IMPROV-UNDER-HYPNOSIS/Ticket' target='_blank'>HYPROV COVID Policy on the Find Tickets Page.</Link></Text>,
      ];
    } else if (this.props.show.name.includes("Stranger Sings")) {
      return [
        <Text>All audience members are required to wear masks while in the venue. Please note that due to the on-stage location of the lottery seats, latecomers for lottery seating will not be admitted.</Text>,
        <Text>Ticket pick up starts 60 minutes before the show, and no later than 15 minutes prior to curtain. Management reserves the right to sell lottery seating if not picked up 15 minutes prior to showtime.</Text>,
        <Text>Payment is cash only with exact change. The box office will require exactly $20 in cash per ticket.</Text>,
      ];
    }
  
  }

  render() {
    const datetime = new Date(this.props.show.time);
    const date = toDateString(datetime);
    const time = toTimeString(datetime);
    return (
      <LotteryContainer showName={this.props.show.name + " Ticket Lottery"}>
        <Text fontSize='xl'><b>Location: </b>{this.renderLocation()}</Text>
        <Text fontSize='xl'><b>Date: </b>{date}</Text>
        <Text fontSize='xl'><b>Time: </b>{time}</Text>
        <Text fontSize='xl' pb={3}><b>Cost per ticket: </b>${this.props.show.cost}</Text>

        <Box display='flex' alignItems='baseline'>
          <FormLabel htmlFor='name'>Name: </FormLabel>
          {this.maybeRenderName()}
        </Box>

        <Box display='flex' alignItems='baseline'>
          <FormLabel htmlFor='email'>Email: </FormLabel>
          {this.maybeRenderEmail()}
        </Box>

        <Box display='flex' alignItems='baseline'>
          <FormLabel htmlFor='numTickets'>Number of tickets: </FormLabel>
          <ButtonGroup id='numTickets' size='sm' isAttached variant='outline'>
            <Button mr='-px' variant={this.getNumTicketsVariant(1)} colorScheme="button" onClick={() => this.handleNumTickets(1)} disabled={this.state.isSubmitting}>1</Button>
            <Button mr='-px' variant={this.getNumTicketsVariant(2)} colorScheme="button" onClick={() => this.handleNumTickets(2)} disabled={this.state.isSubmitting}>2</Button>
          </ButtonGroup>
        </Box>

        <FormLabel>Want to enter for other dates?</FormLabel>
        {this.renderOtherDates()}

        <Divider pt={5} />

        <FormLabel>If your name isn't selected as a lottery winner, there is a chance we may have unsold or returned tickets available on short notice. Pick your price, and we'll notify you if any tickets become available at the last minute. </FormLabel>
        {this.renderPriceSlider()}

        <Divider pt={10} />

        {this.renderExtraTerms()}

        <Divider pt={2} />

        {this.maybeRenderSubmitButton()}
      </LotteryContainer>
    );
  }
}