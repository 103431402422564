import { Component } from 'react';
import Header from './Header';
import { Grid, GridItem, Text } from '@chakra-ui/layout';
import { cancelTickets } from './utils/networkHelpers';
import { withRouter } from "react-router-dom";
import { BackgroundColour } from './utils/colourConstants';
import LotteryContainer from './LotteryContainer';
import Loading from './Loading';

class CancelWin extends Component {
  state = {
    loaded: false,
    success: false,
    error: null,
    showName: '',
  };

  componentDidMount() {
    const entryId = this.props.match.params.id;
    cancelTickets(entryId)
      .then(res => {
        this.setState({ loaded: true, ...res });
      });
  }

  renderPage() {
    if (this.state.loaded) {
      if (this.state.success) {
        return <LotteryContainer showName={this.state.showName}>
          <Text fontSize='xl'>Cancelled</Text>
          <Text fontSize='l'>We're sorry to hear that you can no longer join us at {this.state.showName} but we appreciate you letting us know!</Text>
          <Text fontSize='l'>We look forward to seeing you soon at a different performance!</Text>
        </LotteryContainer>;
      }
      return <LotteryContainer showName='Error' hideImage>
        <Text fontSize='xl'>Error cancelling tickets.</Text>
      </LotteryContainer>;
    }

    return <Loading />;
  }

  render() {
    return (
      <div>
        <Header />
        <Grid
          h='100%'
          pb='50px'
          minHeight='80vh'
          px={{ base: 5, sm: 10, md: 20, lg: 40, xl: 0 }}
          templateColumns='repeat(4, 1fr)'
          gap={4}
          bg={BackgroundColour}
        >
          <GridItem colSpan={{ base: 0, xl: 1 }} />
          <GridItem colSpan={{ base: 4, xl: 2 }}>
            {this.renderPage()}
          </GridItem>
          <GridItem colSpan={{ base: 0, xl: 1 }} />
        </Grid>
      </div>
    );
  }
}

export default withRouter(CancelWin);
