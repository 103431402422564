import { Component } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Root from './Root';
import Loading from './Loading';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import ConfirmWin from './ConfirmWin';
import CancelWin from './CancelWin';
import PayWin from './PayWin';

const theme = extendTheme({
  fonts: {
    heading: 'Lato, sans-serif',
    body: 'Lato, sans-serif',
  },
  styles: {
    global: {
      body: {
        color: '#D7D7D7'
      }
    }
  },
  colors: {
    button: {
      50: "#84a4c2",
      100: "#84a4c2",
      500: "#3182ce",
      600: "#BBBBBB"
    }
  }
});

export default class App extends Component {
  render() {
    return (
      <ChakraProvider theme={theme}>
        <Router>
            <Route path="/show/:name">
              <Root />
            </Route>
            <Route path="/confirm/:id">
              <ConfirmWin />
            </Route>
            <Route path="/pay/:id">
              <PayWin />
            </Route>
            <Route path="/cancel/:id">
              <CancelWin />
            </Route>
        </Router>
      </ChakraProvider>
    );
  }
}