import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import strangersings from './strangersings.jpg';
import hyprov from './hyprov.png';

export default class LotteryContainer extends Component {
  static propTypes = {
    hideImage: PropTypes.bool,
    showName: PropTypes.string,
  };

  renderImage() {
    if (this.props.hideImage) {
      return;
    }
    // TODO: Improve this
    if (this.props.showName.includes("Hyprov")) {
      return <Image src={hyprov} boxSize='200px' mr={5} />;
    } else if (this.props.showName.includes("Stranger Sings")) {
      return <Image src={strangersings} boxSize='200px' mr={5} />;
    }
    
    return <Image src={hyprov} boxSize='200px' mr={5} />;
  }

  render() {
    return (
      <Box bg="#4d5873" px={10} py={6} w='100%' borderRadius='lg' overflow='hidden'>
        <Stack spacing={3}>
          <Box display='flex' alignItems='center'>
            {this.renderImage()}
            <Text fontSize='4xl'>{this.props.showName}</Text>
          </Box>
          {this.props.children}
        </Stack>
      </Box>
    );
  }
}