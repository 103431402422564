import { Component } from 'react';
import Header from './Header';
import Lottery from './Lottery';
import { Grid, GridItem, Text } from '@chakra-ui/layout';
import { getShowInfoForUser, loadData } from './utils/networkHelpers';
import Loading from './Loading';
import { withRouter } from "react-router-dom";
import { getUserId } from './utils/cookieHelpers';
import SuccessfulEntry from './SuccessfulEntry';
import { BackgroundColour } from './utils/colourConstants';
import LotteryContainer from './LotteryContainer';

class Root extends Component {
  state = {
    show: null,
    userData: null,
    isLoggedIn: false,
    hasEntered: false,
  };

  componentDidMount() {
    const showName = this.props.match.params.name;
    loadData(showName)
      .then(show => {
        this.setState({ show });
      });
    const userId = getUserId();
    this.setState({ isLoggedIn: getUserId() !== undefined });
    if (userId !== undefined) {
      getShowInfoForUser(showName, userId)
        .then(userData => {
          console.log(userData);
          this.setState({ userData });
        })
    }
  }

  handleOnSubmit = () => {
    this.setState({ hasEntered: true });
  }

  renderEntryPage = () => {
    if (this.state.show == null || (this.state.isLoggedIn && this.state.userData == null)) {
      return <Loading />;
    }

    if (this.state.hasEntered) {
      return <SuccessfulEntry showName={this.state.show.name} />;
    }
    return <Lottery show={this.state.show} userData={this.state.userData} onSubmit={this.handleOnSubmit} />;
  }

  render() {
    return (
      <div>
        <Header userData={this.state.userData} />
        <Grid
          h='100%'
          minHeight='80vh'
          pb='50px'
          px={{ base: 5, sm: 10, md: 20, lg: 40, xl: 0 }}
          templateColumns='repeat(4, 1fr)'
          gap={4}
          bg={BackgroundColour}
        >
          <GridItem colSpan={{ base: 0, xl: 1 }} />
          <GridItem colSpan={{ base: 4, xl: 2 }}>
            {this.renderEntryPage()}
          </GridItem>
          <GridItem colSpan={{ base: 0, xl: 1 }} />
        </Grid>
      </div>
    );
  }
}

export default withRouter(Root);
