import { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/layout';
import LotteryContainer from './LotteryContainer';

export default class SuccessfulEntry extends Component {
  static propTypes = {
    showName: PropTypes.string,
  };

  render() {
    return (
      <LotteryContainer showName={this.props.showName}>
        <Text fontSize='xl'>Successfully entered for {this.props.showName}! Good luck!</Text>
      </LotteryContainer>
    );
  }
}