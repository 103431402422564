import { setUserId } from "./cookieHelpers";

const BASE_URL = 'https://cloudrun2-xaqxxyw3tq-uc.a.run.app';

export function loadData(showUrl) {
  return fetch(`${BASE_URL}/getShow?url=${showUrl}`)
  .then(res => {
    return res.json();
  })
}

export function getShowInfoForUser(showUrl, userId) {
  return fetch(`${BASE_URL}/getShowInfoForUser?url=${showUrl}&userId=${userId}`)
  .then(res => {
    return res.json();
  })
}

export function enterShow(showIds, numTickets, minCost, userId, firstName, lastName, email) {
  return fetch(`${BASE_URL}/enterShow`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        showIds,
        numTickets,
        minCost,
        userId,
        firstName,
        lastName,
        email
      })
    })
    .then(res => {
      return res.json();
    }).then(res => {
      setUserId(res.userId);
      return res;
    });
}

export function confirmTickets(entryId) {
  return fetch(`${BASE_URL}/confirmTickets`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        entryId
      })
    })
    .then(res => {
      return res.json();
    });
}

export function payTickets(entryId, amount, token) {
  return fetch(`${BASE_URL}/payTickets`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        entryId,
        amount,
        token
      })
    })
    .then(res => {
      return res.json();
    });
}

export function cancelTickets(entryId) {
  return fetch(`${BASE_URL}/cancelTickets`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        entryId
      })
    })
    .then(res => {
      return res.json();
    });
}
