import { Component } from 'react';
import Header from './Header';
import { Grid, GridItem, Text } from '@chakra-ui/layout';
import { confirmTickets } from './utils/networkHelpers';
import { withRouter } from "react-router-dom";
import { BackgroundColour } from './utils/colourConstants';
import LotteryContainer from './LotteryContainer';
import Loading from './Loading';
import { toDateString, toTimeString } from './utils/dateHelpers';

class ConfirmWin extends Component {
  state = {
    loaded: false,
    success: false,
    error: null,
    showName: '',
    showTime: '',
    cost: 0,
    numTickets: 0,
    firstName: '',
  };

  componentDidMount() {
    const entryId = this.props.match.params.id;
    confirmTickets(entryId)
      .then(res => {
        this.setState({ loaded: true, ...res });
      });
  }

  renderPage() {
    if (this.state.loaded) {
      if (this.state.success) {
        const datetime = new Date(this.state.showTime);
        return <LotteryContainer showName={this.state.showName}>
          <Text fontSize='xl'>Congratulations {this.state.firstName}!</Text>
          <Text fontSize='l'>You have successfully won and confirmed {this.tickets} on {toDateString(datetime)} at {toTimeString(datetime)}!</Text>
          <Text fontSize='l'>Please check your emails for a confirmation email. Please bring this confirmation to the box office, along with ${this.ticketCost}.</Text>
          <Text fontSize='l'>Enjoy the show!</Text>
        </LotteryContainer>;
      }
      return <LotteryContainer showName='Error' hideImage>
        <Text fontSize='xl'>Error confirming tickets.</Text>
      </LotteryContainer>;
    }

    return <Loading />;
  }

  get numTickets() {
    return parseInt(this.state.numTickets);
  }

  get ticketCost() {
    return this.numTickets * parseInt(this.state.cost);
  }

  get tickets() {
    if (this.numTickets === 1) {
      return '1 ticket';
    }
    return `${this.state.numTickets} tickets`;
  }

  render() {
    return (
      <div>
        <Header />
        <Grid
          h='100%'
          pb='50px'
          minHeight='80vh'
          px={{ base: 5, sm: 10, md: 20, lg: 40, xl: 0 }}
          templateColumns='repeat(4, 1fr)'
          gap={4}
          bg={BackgroundColour}
        >
          <GridItem colSpan={{ base: 0, xl: 1 }} />
          <GridItem colSpan={{ base: 4, xl: 2 }}>
            {this.renderPage()}
          </GridItem>
          <GridItem colSpan={{ base: 0, xl: 1 }} />
        </Grid>
      </div>
    );
  }
}

export default withRouter(ConfirmWin);
