import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Link, Spacer, Text } from '@chakra-ui/layout';
import { logOut } from './utils/cookieHelpers';
import logo from './logo.png';
import { Image } from '@chakra-ui/react';
import { BackgroundColour } from './utils/colourConstants';


export default class Header extends Component {
  static propTypes = {
    userData: PropTypes.object,
  };

  handleLogOut = () => {
    logOut();
  }

  maybeRenderWelcome = () => {
    if (this.props.userData) {
      return <Text color="#FFFFFF">Welcome {this.props.userData.firstName}! <Link color='grey' onClick={this.handleLogOut}>(Not you?)</Link></Text>
    }
  }

  render() {
    return (
      <Flex backgroundColor={BackgroundColour}>
        <Box px='8' display='flex' alignItems='center'>
          <Image src={logo} boxSize='200px' mr={5} />
        </Box>
        <Spacer />
        <Box p='4' display='flex' pt='12'>
          {this.maybeRenderWelcome()}
        </Box>
      </Flex>
    );
  }
}