import Cookies from 'universal-cookie';

export function setUserId(userId) {
  const cookies = new Cookies();
  cookies.set('userId', userId, { path: '/' });
}

export function getUserId() {
  const cookies = new Cookies();
  return cookies.get('userId');
}

export function logOut() {
  const cookies = new Cookies();
  cookies.remove('userId', { path: '/' });
  window.location.reload(false);
}
